// TODO 타입 가드 추가 하기
export function isEmpty(value: unknown): value is null | undefined | '' | [] | Record<string, never> {
  // Nullish values are empty
  if (value == null) {
    return true;
  }

  // Arrays and strings have length
  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length === 0;
  }

  // Check for Maps and Sets
  if (value instanceof Map || value instanceof Set) {
    return value.size === 0;
  }

  // Check if it's an object and has no enumerable properties
  if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  }

  if (typeof value === 'undefined' || typeof value === 'number' || typeof value === 'boolean') {
    return true;
  }
  // All other types are not considered empty
  return false;
}
