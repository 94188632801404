let idCounter: Record<string, number> = {};

export function uniqueId(prefix: string = ''): string {
  idCounter[prefix] = (idCounter[prefix] || 0) + 1;
  return `${prefix}${idCounter[prefix]}`;
}

export function resetUniqueIdCounter(): void {
  idCounter = {};
}
